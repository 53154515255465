import React from 'react';
import styled from 'styled-components';
import { graphql, navigate } from 'gatsby';
import Img from 'gatsby-image';
import { Col, Row, Typography, Button } from 'antd';

import MainContentWrapper from '../components/MainContentWrapper';
import SEO from '../components/SEO';
import FullWidthBackground from '../components/FullWidthBackground';
import CenterContent from '../components/CenterContent';
import MarkdownHTMLAst from '../components/MarkdownHTMLAst';

const { Title: BaseTitle, Paragraph } = Typography;

const Title = styled(BaseTitle)`
  text-align: center;
  margin-top: 4rem;
`;

const BackgroundContentTitle = styled(Title)`
  color: ${props => props.theme.colors.white}!important;
  padding: 1rem;
`;

const Tile = styled(Row)`
  margin: 5rem 0;
  &:first-of-type {
    margin-top: 0;
  }
  &:last-of-type {
    margin-bottom: 0;
  }
`;

const TileImage = styled(Img)`
  height: 30rem;
  ${({ theme }) => `
    ${theme.breakPoints.up('xs')} {
      height: 40rem;
    }
  `}
`;

const CovidParagraph = styled(Paragraph)`
  text-align: center;
`;

const CovidImg = styled(Img)`
  height: 23rem;
  width: 30rem;
  border-radius: 1rem;
  box-shadow: 0 0 3rem 0.5rem ${props => props.theme.colors.grey.transparent};
  ${({ theme }) => `
    ${theme.breakPoints.up('xxs')} {
      height: 26rem;
      width: 34rem;
    }

    ${theme.breakPoints.up('sm')} {
      height: 37rem;
      width: 47rem;
    }
  `}
`;

const IndexPage = ({ data }) => {
  return (
    <>
      <SEO
        seo={{
          title: 'Home page',
          metas: [
            {
              name: 'description',
              content: 'DRD event decorations',
            },
          ],
        }}
      />
      <FullWidthBackground data={data.heroImage} title="hero image">
        <CenterContent>
          <BackgroundContentTitle>DRD‘S Venue Decor</BackgroundContentTitle>
          <Button ghost size="large" onClick={_ => navigate('/contact-us')}>
            Contact Us
          </Button>
        </CenterContent>
      </FullWidthBackground>
      <MainContentWrapper>
        <CenterContent>
          <CovidParagraph strong>
            We have placed strict guidelines for you and your guests to stay
            safe at our events.
          </CovidParagraph>
          <CovidImg
            fluid={data.covidImage.childImageSharp.fluid}
            alt="Covid-19 certificate"
          />
        </CenterContent>
        <Title level={2} underline>
          Our services
        </Title>
        {data.servicesContent.nodes.map((service, index) => {
          return (
            <Tile
              key={data.servicesImages.nodes[index].childImageSharp.id}
              align="middle"
              gutter={[{ sm: 16 }, { xs: 16, sm: 0 }]}
            >
              <Col span="24" sm={{ span: 12, order: index % 2 }}>
                <TileImage
                  fluid={data.servicesImages.nodes[index].childImageSharp.fluid}
                  alt={service.frontmatter.imageName}
                />
              </Col>
              <Col span="24" sm={{ span: 12 }}>
                <MarkdownHTMLAst data={service.htmlAst.children} />
              </Col>
            </Tile>
          );
        })}
      </MainContentWrapper>
    </>
  );
};

export const pageQuery = graphql`
  query indexPageQuery {
    heroImage: file(relativePath: { eq: "page/index/index-hero.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1500, maxHeight: 500, jpegQuality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    covidImage: file(
      relativePath: { eq: "page/index/covid-certificate.jpeg" }
    ) {
      childImageSharp {
        fluid(
          maxWidth: 485
          maxHeight: 375
          jpegQuality: 100
          cropFocus: CENTER
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    servicesImages: allFile(
      filter: { relativeDirectory: { eq: "services" } }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        childImageSharp {
          fluid(maxWidth: 1200, jpegQuality: 100) {
            ...GatsbyImageSharpFluid
          }
          id
        }
      }
    }
    servicesContent: allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "service" } } }
      sort: { fields: frontmatter___imageName, order: ASC }
    ) {
      nodes {
        frontmatter {
          imageName
        }
        htmlAst
      }
    }
  }
`;
export default IndexPage;
